import React from "react";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

import Universalbreadcreumb from "../components/home/universal-breadcumb";

import Universalsubcontent from "../components/home/universal-content";

import Universalpdfcontent from "../components/home/universal-pdf";

// markup
const UniversalprayerPage = () => {
  return (
    <div>
      <Seo title="Universal Prayer " />

      <Layout isSticky>
        <section>
          <Universalbreadcreumb />
        </section>

        <section>
          <Universalsubcontent />
        </section>
        <section>
            <Universalpdfcontent />
        </section>
      </Layout>
    </div>
  );
};

export default UniversalprayerPage;
