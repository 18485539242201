import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";

export default function Universalbreadcreumb() {
  return (
    <>
    <div className="breadcumb-sub-page-top white-banner subpagepadding-left" >
  <div style={{display: "flex",
    justifyContent: "space-between"}}
  >
  <div classname="breadcumb-left" >
                <Breadcrumb className="breadcumb-left">
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item active style={{color:"black"}}>
                    {" "}
                    UNIVERSAL PRAYER AT 9 PM
                  </Breadcrumb.Item>
                </Breadcrumb>
                
              </div>
    <div>

    </div>
  </div>
    </div>
    <div className="breadcumb-sub-page">
      <div className="container">
      
        <div className="row">
          <div className="col-md-12 pad40">
            <div className="inner-title text-center">
              <h3 className="heading-wrapper universal-color">UNIVERSAL PRAYER AT 9 PM </h3>
             
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}
