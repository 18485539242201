import React from "react";

import manyoga from "../../assets/images/home/universal-prayer-new.png";

import Universalaudio from "../../components/home/universalaudio";

import womanyoga from "../../assets/images/home/the-evolution-of-consciousness-new.png";

export default function Universalsubcontent() {
  return (
    <section className="universal-content-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <img className="img-fluid" src={manyoga} alt="universal prayer" />
          </div>
          <div className="col-md-6 pad30-left-right">
          <div className="universal-prayer-main-text">
            <p className="theme-p-tag">
              {" "}
              The universal prayer is a daily Heartfulness practice that is
              dedicated to the welfare of all.
            </p>
            <p className="theme-p-tag">
              {" "}
              At 9:00 p.m. sharp, everyone who would like to do so, wherever
              they might be at the time, may stop their activities and meditate
              for fifteen minutes, thinking that all brothers and sisters are
              being filled with love and devotion and that real faith is growing
              stronger in them. It shall be of immense value to them, which
              their practical experience only shall reveal.
            </p>
            </div>
          </div>
        </div>
        <div className="row block-order">
          <div className="col-md-6 pad30-left-right">
              <div className="universal-prayer-main-text">
              <p className="text-right theme-p-tag">
              As we offer this prayer at 9 p.m. local time, when many of us in
              different parts of the world practice it, prayers for the
              spiritual welfare of all humankind are continuously being offered
              during the 24-hour cycle of every day. Everyone is invited and
              encouraged to join in this universal prayer.
            </p>
            <p className="text-right theme-p-tag"> <b>Daaji speaking about the Universal Prayer </b> </p>
            <p class="text-right">
            <Universalaudio />
                <a href="">Listen Audio</a>
              
            </p>
             
            </div>
          </div>

          <div className="col-md-6">
            <img className="img-fluid" src={womanyoga} alt="universal prayer" />
          </div>
        </div>
      </div>
      
    </section>
  );
}
