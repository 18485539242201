import React from "react";
import { Modal } from "react-bootstrap";
// import { StaticImage } from "gatsby-plugin-image";
// import { config } from "../assets/data/videotem";

// import config from "../../assets/data/videoitem";

import ReactAudioPlayer from "react-audio-player";

import audioicon from "../../assets/images/home/audio-icon.png";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      className="audiopopup-wrapper"
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/* <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header> */}
      <Modal.Body className="bg-leaf">
      <button type="button" className="close custom-closeicon" aria-hidden="true" onClick={props.onHide} >&times;</button>
        <ReactAudioPlayer
          src="https://cdn-prod.heartfulness.org/hfn/daily-practices/Heartfulness_Cleaning_English.mp3"
          autoPlay={false}
          controls
        />
      </Modal.Body>
    </Modal>
  );
}

function  Universalaudio() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <span
        onKeyDown={() => setModalShow(true)}
        onClick={() => setModalShow(true)}
        role="button"
        tabIndex="0"
      >
        <img className="mg-right10" src={audioicon} alt="youtube" />
      </span>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default Universalaudio;
