import React from "react";

export default function Universalpdfcontent() {
  return (
    <section className="universalpdf-wrapper">
      <div class="py-4 my-2">
        <div className="container">

          <div className="row">
            <div className="col-md-12 pad40">
              <div className="inner-title text-center">
                <h3 className="heading-wrapper universal-color">UNIVERSAL PRAYER AT 9 PM </h3>

              </div>
              <div className=" row mx-5">
              <div className="col-md-12 mt-2">
                  <h4 class=" universal-prayer-subsec-title theme-color theme-h4">
                  in local south african languages
                  </h4>
                </div>
                <div className="my-4">
              <a
                class="btn btn-blue mx-4  m-lg-0"
                href="https://cdn-prod.heartfulness.org/hfn/files/2020/Heartfulness-Universal-Prayer.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                VIEW PDF
              </a>
            </div>
            </div>
            </div>
           
          </div>

        </div>
        
       
      </div>
    </section>
  );
}
